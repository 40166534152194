// FIND A broker
$(function() {
    $('#bh-sl-map-container').storeLocator({
        'originMarker': false,
        'markerImg': '/webroot/img/sitewide/map--pin.png',
        'markerDim': {
            'width': 29,
            'height': 47,
        },
        'bounceMarker': true,
        'dataType': 'xml',
        'dataLocation' : '/carpets/locations.php',
        'infowindowTemplatePath' : '/carpets/infowindow-description.html',
        'listTemplatePath' : '/carpets/location-list-description.html',
        'mapSettings' : {
            zoom : 9,
            mapTypeControl: false,
            fullscreenControl: false,
        },
        'pagination': false,
        'storeLimit': -1,
        'listColor2': '#FFFFFF',
        'distanceAlert': 1000,
        // 'fullMapStart': true,
        // 'slideMap':false,
    });
});
//
